<template lang="pug">
    .page-login.py-12
        v-container(:style="{maxWidth: '600px'}").pa-5
            v-row
                v-col.login-area.pa-6.px-md-12
                    template(v-if="!showResetPassword")
                        v-img(src="logo-app.png" width="164px" contain).mx-auto.mb-12
                        p Please enter your info to sign in.
                        v-text-field(label="Your Email", placeholder="your.name@agency.com", v-model="username", hint="Required")
                        v-text-field(label="Your Password", placeholder="xyz123", v-model="password", :type="showPasswordInput ? 'text' : 'password'", hint="Your password is at least 8 characters, and it contains at least 1 number", :append-icon="showPasswordInput ? 'mdi-eye' : 'mdi-eye-off'", @click:append="() => (showPasswordInput = !showPasswordInput)")
                        v-row.mt-4(justify="center", justify-md="space-between" align="center").text-center
                            v-col(cols="12" md="8")
                                v-btn(color="primary" block depressed :loading="loading" :disabled="!readyToProceed" @click="doLogin")
                                    span Sign In to start
                                    v-icon.ml-2 mdi-arrow-right
                            v-col(cols="12" md="4")
                                v-btn(color="primary" outlined to="/register")
                                    span ...or register

                        .errors.mt-3
                            .login-error.red--text(v-if="loginError") {{loginError}}
                            .reset-complete.mt-2(v-if="resetComplete") A reset email was sent to {{username}}. It contains a link that lets you reset your password.

                        //- .text-center.mt-3(v-if="!resetComplete")
                        //-     a.font-style-oblique.no-border(href="#" @click.prevent="showResetPassword=true")
                        //-     em Did you forget your password?

                    template(v-if="showResetPassword")
                      p Please enter your email to receive a password reset link.
                      v-text-field(label="Your Email", placeholder="your.name@agency.com", v-model="username", hint="Required")

                      v-row.mt-4(justify="center", justify-md="space-between" align="center").px-3.text-center
                          v-btn(color="primary" :loading="loading" :disabled="!isValidEmail(username)" @click="doReset").mb-3
                              span Reset password
                              v-icon.ml-2 mdi-reset
                          v-btn(color="primary" outlined @click="showResetPassword=false").mb-3
                              span ...back to login

                      .errors.mt-3
                        .reset-error.error--text(v-if="resetError") Error resetting your password: {{resetError}}

</template>

<script>
import { call } from "vuex-pathify";
import { isValidEmail, isValidPassword } from "@/helpers.js";
export default {
  name: "LoginPage",
  computed: {
    readyToProceed() {
      return isValidEmail(this.username) && isValidPassword(this.password);
    }
  },
  data() {
    return {
      username: "sam.anders@example.com",
      password: "abc123abc",
      loginError: null,
      loading: false,
      showResetPassword: false,
      showPasswordInput: false,
      resetComplete: false,
      resetError: null
    };
  },
  methods: {
    ...call({ signIn: "User/signIn", bindUserData: "User/bindUserData" }),
    isValidEmail(str) {
      return isValidEmail(str);
    },
    async doLogin() {
      try {
        this.loading = true;
        this.loginError = null;
        const loginRes = await this.signIn({
          email: this.username,
          password: this.password
        });

        console.log("Login res is", loginRes);

        if (loginRes && loginRes.error) {
          this.loginError = loginRes.error;
          this.loading = false;
        } else if (loginRes && loginRes.id) {
          console.log("loginRes", loginRes);
          const originalPath =
            this.$route.query && this.$route.query.originalPath
              ? this.$route.query.originalPath
              : "/crm/Leads";

          if (this.$router.path !== originalPath)
            this.$router.replace({ path: originalPath });
        } else {
          this.loginError =
            "There was a strange error while trying to log you in. Please contact us and we'll fix it immediately.";
        }
      } catch (err) {
        console.error(err);
        this.loginError = err;
      } finally {
        this.loading = false;
      }
    },
    async doReset() {
      this.resetError = null;
      this.loading = true;
      const resetRes = await this.resetPassword({ email: this.username });
      window.debug("Got res of reset", resetRes);
      if (resetRes && resetRes.error) {
        this.resetError = resetRes.error.message;
      } else {
        this.showResetPassword = false;
        this.resetComplete = true;
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.login-area {
  border: 2px solid rgba($primary, 0.2);
  border-radius: 5px;
}
</style>
