<template lang="pug">
    .registration-page#page-registration.primary
      .max-width
        v-container
          v-row
            v-col(cols="12", md="5").grey.lighten-5.py-12.px-6
              h2.text-center.mb-5.pt-md-12 New Agent Registration
              ol
                li Create beautiful property proposals and send 1 singled combined link for your clients to view all properties
                li Track and monitor how your client views each property
                li Receive follow-up reminders and key insights
                li Need a hand? You can email us at <a class="black--text" href="mailto:support@bgproperty.today">support@bgProperty.today</a>

            v-col(cols="12", md="7").white
              v-form(ref="form", v-model="form"  @submit="handleRegistration")
                .pa-2.pa-md-5
                  v-expand-transition
                    .loading(v-if="loading")
                      h2.display-1.my-12 Registering...
                      v-skeleton-loader(type="card")
                    .ready(v-else)
                      v-img(src="logo-app.png" :width="image.width", min-height="200px" min-width="250px" max-width="60vw" contain).mx-auto
                      v-text-field#agency(v-model="agencyId", label="Agency Code" placeholder="Agency Name", outlined, :rules="[rules.required]", @keypress.space.prevent)
                      v-text-field#firstName(v-model="firstName", label="First name", placeholder="*First Name", outlined, :rules="[rules.required]",)
                      v-text-field#email(v-model="email", label="Your email", type="email", placeholder="*Email address", outlined, :rules="[rules.required, rules.email]")
                      v-text-field#password(v-model="password", label="Password" placeholder="*Password", hint="Your password should contain a number and be at least 8 characters long.", outlined, :rules="[rules.required, rules.password]", :type="showPasswordInput ? 'text' : 'password'", :append-icon="showPasswordInput ? 'mdi-eye' : 'mdi-eye-off'", @click:append="() => (showPasswordInput = !showPasswordInput)", @input="notFound = false")

                      v-banner#sign-in-error.mb-2.error--text.text-center(v-if="registrationError") Ack! We couldn't register you in the system for some reason.

                      v-btn#btn-sign-in(block dark color="primary" depressed x-large @click="handleRegistration").white--text.font-weight-bold.mb-3 Register
                      .text-center.mt-3
                        small Already registered? You can <router-link :to="'/login?email=' + email">Sign In</router-link>.

</template>

<script>
import { isValidEmail, isValidPassword } from "@/helpers";
import { sync, call } from "vuex-pathify";
export default {
  name: "Register",
  components: {},
  data() {
    return {
      image: {
        width: this.$vuetify.breakpoint.smAndDown ? "125px" : "175px",
        height: this.$vuetify.breakpoint.smAndDown ? "125px" : "175px"
      },
      showPasswordInput: false,
      loading: false,
      registrationError: false,
      form: null,
      agencyId: "abc_agency",
      email: "",
      firstName: "Sam",
      password: "",
      userId: "",
      rules: {
        required: value => !!value || "Required.",
        password: value => {
          return (
            isValidPassword(value) ||
            "Your password should contain a number and be at least 8 characters long"
          );
        },
        email: value => {
          return isValidEmail(value) || "Please enter your best e-mail.";
        }
      }
    };
  },
  mounted() {
    const { agencyId, firstName, email } = this.$route.query;
    this.origin = origin;

    if (agencyId) this.agencyId = agencyId;
    if (firstName) this.firstName = firstName;
    if (email) this.email = email;
  },
  computed: {
    ...sync({ userData: "User/userData" })
  },
  methods: {
    ...call({
      register: "User/register"
    }),

    async handleRegistration() {
      try {
        this.loading = true;
        if (this.form) {
          await this.register({
            agencyId: this.agencyId,
            firstName: this.firstName,
            email: this.email,
            password: this.password
          });
          this.$router.push("/crm/Leads");
        } else {
          console.log("Nope, validating form...");
          this.$refs.form.validate();
        }
      } catch (err) {
        if (err.toString().includes("COULD_NOT_CREATE_FIREBASE_USER")) {
          this.registrationError = true;
        }
        console.error(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/colors.scss";
.registration-page {
  min-height: 100vh;

  .max-width {
    max-width: 1200px;
    margin: auto;
  }

  ol li {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  li {
    margin-bottom: 1rem;
  }
}
</style>
